"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.storeProductApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var storeProductApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/storeProduct',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/storeProduct/',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/storeProduct/".concat(id, "/update"),
      data: data,
      method: 'post'
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/storeProduct/".concat(id, "/delete"),
      method: 'post'
    });
  }
};
exports.storeProductApi = storeProductApi;