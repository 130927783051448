"use strict";

var _interopRequireWildcard = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PanelGroup = _interopRequireDefault(require("./components/PanelGroup"));
var dashboard = _interopRequireWildcard(require("@/api/dashboard"));
var _date = require("@/utils/date");
var _Top5Driver = _interopRequireDefault(require("./components/rank/Top5Driver"));
var _Top5Customer = _interopRequireDefault(require("./components/rank/Top5Customer"));
var _Customer = _interopRequireDefault(require("./components/chart/Customer"));
var _OrderFood = _interopRequireDefault(require("./components/chart/OrderFood"));
var _OrderDelivery = _interopRequireDefault(require("./components/chart/OrderDelivery"));
var _OrderTransport = _interopRequireDefault(require("./components/chart/OrderTransport"));
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "DashboardAdmin",
  components: {
    PanelGroup: _PanelGroup.default,
    Top5Driver: _Top5Driver.default,
    Top5Customer: _Top5Customer.default,
    CustomerChart: _Customer.default,
    FoodChart: _OrderFood.default,
    DeliveryChart: _OrderDelivery.default,
    TransportChart: _OrderTransport.default
  },
  data: function data() {
    return {
      patients: [],
      babyKicks: [],
      top5BabyKick: [],
      date: [(0, _moment.default)().subtract(7, "days").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")]
    };
  },
  methods: {}
};
exports.default = _default;