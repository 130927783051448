"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.configApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var configApi = {
  findAll: function findAll() {
    return (0, _request.default)({
      baseURL: process.env.VUE_APP_BASE_URL_MEDIA,
      url: '/v1/public/config'
    });
  },
  findParam: function findParam(params) {
    return (0, _request.default)({
      baseURL: process.env.VUE_APP_BASE_URL_MEDIA,
      url: '/v1/public/config/params',
      params: params
    });
  }
};
exports.configApi = configApi;