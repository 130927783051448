"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tagOptions = exports.WithdrawStatusTrans = exports.WithdrawStatus = exports.TYPE_TRANSACTION = exports.TYPE_CONTENT_DEFINE = exports.StoreStatus = exports.STORE_TYPE = exports.PaymentStatus = exports.PARAMS = exports.ORDER_TYPE = exports.ORDER_TRANSPORT_STATUS = exports.ORDER_FOOD_STATUS = exports.ORDER_DELIVERY_STATUS = exports.CategoryFood = exports.BalanceTypeTrans = exports.BalanceType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _BalanceTypeTrans;
var STORE_TYPE = {
  FOOD: "Thức ăn",
  DRINK: "Thức uống"
};
exports.STORE_TYPE = STORE_TYPE;
var TYPE_TRANSACTION = {
  DEPOSIT: "Nạp tiền",
  WITHDRAW: "Rút tiền",
  INCOME: "Chiết khấu từ đơn hàng",
  REWARD: "Thưởng đăng ký",
  WITHDRAW_PROMOTION: "Rút tiền khuyến mãi",
  DEPOSIT_PROMOTION: "Nạp tiền khuyến mãi",
  WITHDRAW_CANCEL: "Huỷ rút tiền",
  TRANSFER_PROMOTION: "Chuyển tiền khuyến mãi",
  CANCEL_TRANSFER_PROMOTION: "Huỷ chuyển tiền khuyến mãi",
  RECEIVE_TRANSFER_PROMOTION: "Nhận chuyển tiền khuyến mãi",
  ORDER_DELIVERY: "Đơn giao hàng",
  ORDER_FOOD: "Đơn thức ăn",
  REPAY_ORDER: "Hoàn tiền đơn hủy",
  REPAY_ORDER_DELIVERY: "Hoàn tiền đơn hủy (Giao hàng)",
  REPAY_ORDER_FOOD: "Hoàn tiền đơn hủy (Thức ăn)",
  STORE_PARENT: "Hoa hồng giới thiệu",
  STORE_CHILDREN: "Thưởng đăng ký mới (Từ cửa hàng)",
  RECEIVE_MONEY_PRODUCT: 'Tiền hàng (Đơn thức ăn)',
  CUSTOMER_CHILDREN: 'Thưởng đăng ký mới (Từ khách hàng)',
  DRIVER_CHILDREN: 'Thưởng đăng ký mới (Từ tài xế)',
  COMPANY_CHILDREN: "Thưởng đăng ký mới (Từ tổ chức/hộ gia đình)"
};
exports.TYPE_TRANSACTION = TYPE_TRANSACTION;
var BalanceType = {
  Main: "MAIN",
  Promotion: "PROMOTION",
  Credit: "CREDIT"
};
exports.BalanceType = BalanceType;
var BalanceTypeTrans = (_BalanceTypeTrans = {}, (0, _defineProperty2.default)(_BalanceTypeTrans, BalanceType.Main, "Ví chính"), (0, _defineProperty2.default)(_BalanceTypeTrans, BalanceType.Promotion, "Ví khuyến mãi"), _BalanceTypeTrans);
exports.BalanceTypeTrans = BalanceTypeTrans;
var ORDER_FOOD_STATUS = {
  PAY: "Thanh toán",
  FIND_DRIVER: "Đang tìm tài xế",
  ACCEPT_ORDER: "T.xế đang đến cửa hàng",
  COMPLETE: "Đã hoàn thành",
  RUN: "Đang di chuyển",
  CUSTOMER_CANCEL: "Hành khách hủy chuyến",
  DRIVER_CANCEL: "Tài xế hủy chuyến",
  PENDING_PAYMENT: "Chờ thanh toán",
  SCHEDULE: "Chờ đóng gói",
  WAITING: "Chờ xác nhận",
  STAFF_CANCEL: "Hệ thống hủy",
  STORE_CANCEL: "Cửa hàng hủy"
};
exports.ORDER_FOOD_STATUS = ORDER_FOOD_STATUS;
var ORDER_DELIVERY_STATUS = {
  WAITING: "Đang đợi",
  DELIVERING: "Đang giao",
  COMPLETE: "Hoàn thành",
  CANCEL: "Huỷ"
};
exports.ORDER_DELIVERY_STATUS = ORDER_DELIVERY_STATUS;
var ORDER_TRANSPORT_STATUS = {
  WAITING: "Đang đợi",
  DELIVERING: "Đang giao",
  COMPLETE: "Hoàn thành",
  CANCEL: "Huỷ"
};
exports.ORDER_TRANSPORT_STATUS = ORDER_TRANSPORT_STATUS;
var TYPE_CONTENT_DEFINE = {
  ABOUT: "Thông tin chúng tôi",
  FAQ: "Câu hỏi thường gặp",
  HOW_TO_USE: "Cách sử dụng",
  SECURITY: "Bảo mật",
  TERM_CONDITION: "Điều khoản",
  HOW_TO_DEPOSIT: "Hướng dẫn nạp điểm",
  HOW_TO_USE_DRIVER: "Cách sử dụng (Tài xế)",
  FAQ_DRIVER: "Câu hỏi thường gặp (Tài xế)"
};
exports.TYPE_CONTENT_DEFINE = TYPE_CONTENT_DEFINE;
var ORDER_TYPE = {
  FOOD: "Thức ăn",
  TRANSPORT: "Di chuyển",
  DELIVERY: "Giao hàng"
};
exports.ORDER_TYPE = ORDER_TYPE;
var PARAMS = {
  COMMISSION_FOOD: "Thức ăn",
  COMMISSION_DELIVERY: "Giao hàng",
  COMMISSION_TRANSPORT: "Di chuyển "
};
exports.PARAMS = PARAMS;
var tagOptions = {
  ACCEPT_ORDER: {
    type: "warning",
    effect: "plain"
  },
  PENDING_PAYMENT: {
    type: "warning",
    effect: "plain"
  },
  RUN: {
    type: "",
    effect: "plain"
  },
  COMPLETE: {
    type: "success",
    effect: "dark"
  },
  PAY: {
    type: "success",
    effect: "dark"
  },
  CUSTOMER_CANCEL: {
    type: "danger",
    effect: "dark"
  },
  DRIVER_CANCEL: {
    type: "danger",
    effect: "dark"
  },
  STAFF_CANCEL: {
    type: "danger",
    effect: "dark"
  },
  STORE_CANCEL: {
    type: "danger",
    effect: "dark"
  }
};
exports.tagOptions = tagOptions;
var CategoryFood = {
  VEGETARIAN: "Món chay",
  SNACK: "Ăn vặt",
  RICE: "Cơm",
  MILK_TEA: "Trà sữa"
};
exports.CategoryFood = CategoryFood;
var PaymentStatus = {
  Pending: "PENDING",
  Complete: "COMPLETE",
  Fail: "FAIL"
};
exports.PaymentStatus = PaymentStatus;
var WithdrawStatus = {
  Pending: "PENDING",
  Complete: "COMPLETE",
  StaffCancel: "STAFF_CANCEL",
  StoreCancel: "STORE_CANCEL"
};
exports.WithdrawStatus = WithdrawStatus;
var WithdrawStatusTrans = {
  PENDING: "Chờ duyệt",
  COMPLETE: "Hoàn thành",
  STAFF_CANCEL: "Huỷ",
  STORE_CANCEL: "Huỷ"
};
exports.WithdrawStatusTrans = WithdrawStatusTrans;
var StoreStatus = {
  Online: "ONLINE",
  Offline: "OFFLINE"
};
exports.StoreStatus = StoreStatus;