"use strict";

var _interopRequireWildcard = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.map.js");
var _BarLineChart = _interopRequireDefault(require("../BarLineChart"));
var dashbord = _interopRequireWildcard(require("@/api/dashboard"));
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    BarChart: _BarLineChart.default
  },
  props: ["date"],
  data: function data() {
    return {
      series: [],
      dataChart: []
    };
  },
  watch: {
    date: {
      handler: function handler() {
        this.getDataChart();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getDataChart: function getDataChart() {
      var _this = this;
      var listQuery = {
        from: this.date[0],
        to: this.date[1]
      };
      dashbord.orderFoodChart(listQuery).then(function (res) {
        _this.dataChart = res.data.report;
        var series = [];
        series[0] = {
          name: "Doanh số",
          type: "bar",
          barWidth: "60%",
          itemStyle: {
            color: "#1b75bc"
          },
          yAxisIndex: 0,
          data: _this.dataChart.map(function (item) {
            return item.total.moneyTotal;
          })
        };
        series[1] = {
          name: "Đơn hàng",
          type: "line",
          yAxisIndex: 1,
          data: _this.dataChart.map(function (item) {
            return item.total.quantity;
          })
        };
        console.log("series ne", series);
        _this.series = series;
        _this.$nextTick(function () {
          _this.$refs.chart.initChart();
        });
      });
    }
  }
}; // [
//   {
//     name: this.pointerName,
//     type: "bar",
//     stack: "vistors",
//     barWidth: "60%",
//     itemStyle: {
//       color: this.barColor
//     },
//     data: this.dataChart.map(item => {
//       return item[this.attrGet];
//     }),
//     animationDuration
//   }
// ]
exports.default = _default;