import { render, staticRenderFns } from "./SingleImage.vue?vue&type=template&id=6480d275&"
import script from "./SingleImage.vue?vue&type=script&lang=js&"
export * from "./SingleImage.vue?vue&type=script&lang=js&"
import style1 from "./SingleImage.vue?vue&type=style&index=1&id=6480d275&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6480d275')) {
      api.createRecord('6480d275', component.options)
    } else {
      api.reload('6480d275', component.options)
    }
    module.hot.accept("./SingleImage.vue?vue&type=template&id=6480d275&", function () {
      api.rerender('6480d275', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Upload/SingleImage.vue"
export default component.exports