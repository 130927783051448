"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SelectTypeTrans = exports.SelectType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _SelectTypeTrans;
var SelectType;
exports.SelectType = SelectType;
(function (SelectType) {
  SelectType["Checkbox"] = "CHECKBOX";
  SelectType["Radio"] = "RADIO";
})(SelectType || (exports.SelectType = SelectType = {}));
var SelectTypeTrans = (_SelectTypeTrans = {}, (0, _defineProperty2.default)(_SelectTypeTrans, SelectType.Checkbox, 'Được chọn nhiều'), (0, _defineProperty2.default)(_SelectTypeTrans, SelectType.Radio, 'Được chọn một'), _SelectTypeTrans);
exports.SelectTypeTrans = SelectTypeTrans;