"use strict";

var _interopRequireWildcard = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es.string.link.js");
var _vuex = require("vuex");
var _News = _interopRequireDefault(require("./RightSide/News.vue"));
var _NewsModal = _interopRequireWildcard(require("@/views/news/components/NewsModal.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    News: _News.default,
    NewsModal: _NewsModal.default
  },
  name: "AppMain",
  data: function data() {
    return {
      expiredDays: 0
    };
  },
  methods: {
    handleClickNews: function handleClickNews(news) {
      if (news.mode == _NewsModal.ModeType.Link) {
        window.open(news.link, "_blank");
      } else {
        this.$refs.NewsModal.handleUpdate(news);
      }
    }
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    userInfo: function userInfo(state) {
      return state.user.info;
    },
    config: function config(state) {
      return state.config.config;
    }
  })), {}, {
    cachedViews: function cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key: function key() {
      return this.$route.path;
    },
    checkLimit: function checkLimit() {
      if (this.userInfo.balance < +this.config.STORE_LIMIT) {
        return true;
      }
      return false;
    }
  }),
  created: function created() {
    var _this = this;
    this.$store.dispatch("config/getConfig");
    setInterval(function () {
      _this.$store.dispatch("user/getInfo");
    }, 29 * 60 * 1000);
  }
};
exports.default = _default;