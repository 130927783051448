"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAll = findAll;
exports.foodApi = void 0;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
var foodApi = {
  findAll: findAll,
  update: update,
  findDriver: function findDriver(foodOrderId) {
    return (0, _request.default)({
      url: "/orderFood/".concat(foodOrderId, "/findDriver"),
      method: "post"
    });
  },
  cancel: function cancel(foodOrderId) {
    return (0, _request.default)({
      url: "/orderFood/".concat(foodOrderId, "/cancel"),
      method: "post"
    });
  },
  schedule: function schedule(foodOrderId) {
    return (0, _request.default)({
      url: "/orderFood/".concat(foodOrderId, "/schedule"),
      method: "post"
    });
  }
};
exports.foodApi = foodApi;
function findAll(listQuery) {
  return (0, _request.default)({
    url: "/orderFood",
    method: "get",
    params: listQuery
  });
}
function update(orderId, data) {
  return (0, _request.default)({
    url: "/orderFood/".concat(orderId, "/update"),
    method: "post",
    data: data
  });
}