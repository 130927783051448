import { render, staticRenderFns } from "./index.vue?vue&type=template&id=126b135a&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=126b135a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "126b135a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('126b135a')) {
      api.createRecord('126b135a', component.options)
    } else {
      api.reload('126b135a', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=126b135a&scoped=true&", function () {
      api.rerender('126b135a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/components/Settings/index.vue"
export default component.exports