"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.contentDefineApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var contentDefineApi = {
  findAll: function findAll(type) {
    return (0, _request.default)({
      url: '/contentDefine',
      params: {
        type: type
      }
    });
  }
};
exports.contentDefineApi = contentDefineApi;