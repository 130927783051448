"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.withdrawStoreApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var withdrawStoreApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/withdrawStore',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/withdrawStore',
      method: 'post',
      data: data
    });
  },
  cancel: function cancel(id) {
    return (0, _request.default)({
      url: "/withdrawStore/".concat(id, "/delete"),
      method: 'post'
    });
  }
};
exports.withdrawStoreApi = withdrawStoreApi;