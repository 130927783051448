"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Account = _interopRequireDefault(require("./components/Account"));
var _UserCard = _interopRequireDefault(require("./components/UserCard"));
var _Info = _interopRequireDefault(require("./components/Info"));
var _bankInfo = _interopRequireDefault(require("./components/bank-info.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Profile",
  components: {
    Account: _Account.default,
    UserCard: _UserCard.default,
    Info: _Info.default,
    BankInfo: _bankInfo.default
  },
  data: function data() {
    return {
      activeTab: "info"
    };
  },
  methods: {}
};
exports.default = _default;