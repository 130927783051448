import { render, staticRenderFns } from "./PayHistory.vue?vue&type=template&id=38d15210&"
import script from "./PayHistory.vue?vue&type=script&lang=js&"
export * from "./PayHistory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('38d15210')) {
      api.createRecord('38d15210', component.options)
    } else {
      api.reload('38d15210', component.options)
    }
    module.hot.accept("./PayHistory.vue?vue&type=template&id=38d15210&", function () {
      api.rerender('38d15210', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/driver/components/PayHistory.vue"
export default component.exports