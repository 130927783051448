import { render, staticRenderFns } from "./News.vue?vue&type=template&id=17e09848&scoped=true&"
import script from "./News.vue?vue&type=script&lang=ts&"
export * from "./News.vue?vue&type=script&lang=ts&"
import style0 from "./News.vue?vue&type=style&index=0&id=17e09848&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17e09848",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('17e09848')) {
      api.createRecord('17e09848', component.options)
    } else {
      api.reload('17e09848', component.options)
    }
    module.hot.accept("./News.vue?vue&type=template&id=17e09848&scoped=true&", function () {
      api.rerender('17e09848', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/components/RightSide/News.vue"
export default component.exports