"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PromotionTypeTrans = exports.PromotionType = exports.PromotionProductApplyType = exports.PromotionPeriodTypeTrans = exports.PromotionPeriodType = exports.PromotionOrderType = exports.DiscountType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _PromotionTypeTrans, _PromotionPeriodTypeT;
var PromotionProductApplyType;
exports.PromotionProductApplyType = PromotionProductApplyType;
(function (PromotionProductApplyType) {
  PromotionProductApplyType["All"] = "ALL";
  PromotionProductApplyType["Selection"] = "SELECTION";
})(PromotionProductApplyType || (exports.PromotionProductApplyType = PromotionProductApplyType = {}));
var DiscountType;
exports.DiscountType = DiscountType;
(function (DiscountType) {
  DiscountType["Price"] = "PRICE";
  DiscountType["Percent"] = "PERCENT";
})(DiscountType || (exports.DiscountType = DiscountType = {}));
var PromotionPeriodType;
exports.PromotionPeriodType = PromotionPeriodType;
(function (PromotionPeriodType) {
  PromotionPeriodType["Day"] = "DAY";
  PromotionPeriodType["Week"] = "WEEK";
  PromotionPeriodType["Month"] = "MONTH";
  PromotionPeriodType["All"] = "ALL";
})(PromotionPeriodType || (exports.PromotionPeriodType = PromotionPeriodType = {}));
var PromotionOrderType;
exports.PromotionOrderType = PromotionOrderType;
(function (PromotionOrderType) {
  PromotionOrderType["Order"] = "ORDER";
  PromotionOrderType["OrderFood"] = "ORDER_FOOD";
  PromotionOrderType["OrderDelivery"] = "ORDER_DELIVERY";
})(PromotionOrderType || (exports.PromotionOrderType = PromotionOrderType = {}));
var PromotionType;
exports.PromotionType = PromotionType;
(function (PromotionType) {
  PromotionType["Ship"] = "SHIP";
  PromotionType["Product"] = "PRODUCT";
})(PromotionType || (exports.PromotionType = PromotionType = {}));
var PromotionTypeTrans = (_PromotionTypeTrans = {}, (0, _defineProperty2.default)(_PromotionTypeTrans, PromotionType.Product, 'Tiền hàng'), (0, _defineProperty2.default)(_PromotionTypeTrans, PromotionType.Ship, 'Phí ship'), _PromotionTypeTrans);
exports.PromotionTypeTrans = PromotionTypeTrans;
var PromotionPeriodTypeTrans = (_PromotionPeriodTypeT = {}, (0, _defineProperty2.default)(_PromotionPeriodTypeT, PromotionPeriodType.All, 'Cả chương trình'), (0, _defineProperty2.default)(_PromotionPeriodTypeT, PromotionPeriodType.Day, 'Theo ngày'), (0, _defineProperty2.default)(_PromotionPeriodTypeT, PromotionPeriodType.Week, 'Theo tuần'), (0, _defineProperty2.default)(_PromotionPeriodTypeT, PromotionPeriodType.Month, 'Theo tháng'), _PromotionPeriodTypeT);
exports.PromotionPeriodTypeTrans = PromotionPeriodTypeTrans;