"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.customerChart = customerChart;
exports.getDashboard = getDashboard;
exports.orderDeliveryChart = orderDeliveryChart;
exports.orderFoodChart = orderFoodChart;
exports.orderTransportChart = orderTransportChart;
exports.summary = summary;
exports.top5Customer = top5Customer;
exports.top5Driver = top5Driver;
var _request = _interopRequireDefault(require("@/utils/request"));
function getDashboard(params) {
  return (0, _request.default)({
    url: "/dashboard",
    method: "get",
    params: params
  });
}
function customerChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/customerLast30",
    method: "get",
    params: listQuery
  });
}
function orderFoodChart(listQuery) {
  return (0, _request.default)({
    url: "/orderFood/last30",
    method: "get",
    params: listQuery
  });
}
function orderDeliveryChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/orderDeliveryLast30",
    method: "get",
    params: listQuery
  });
}
function orderTransportChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/orderTransportLast30",
    method: "get",
    params: listQuery
  });
}
function top5Driver() {
  return (0, _request.default)({
    url: "/dashboard/top5DriversThisMonth",
    method: "get"
  });
}
function top5Customer() {
  return (0, _request.default)({
    url: "/dashboard/top5CustomerThisMonth",
    method: "get"
  });
}
function summary(params) {
  return (0, _request.default)({
    url: "/orderFood/summary",
    method: "get",
    params: params
  });
}