"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.merchandiseApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var merchandiseApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/merchandise',
      params: params
    });
  }
};
exports.merchandiseApi = merchandiseApi;