"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.findAll = findAll;
exports.orderDelivery = orderDelivery;
exports.orderFood = orderFood;
exports.orderTransport = orderTransport;
exports.resetPassword = resetPassword;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
function findAll(listQuery) {
  return (0, _request.default)({
    url: "/driver",
    params: listQuery
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/driver",
    method: "post",
    data: data
  });
}
function resetPassword(id, data) {
  return (0, _request.default)({
    url: "/driver/".concat(id, "/resetPassword"),
    method: "post",
    data: data
  });
}
function update(driverId, data) {
  return (0, _request.default)({
    url: "/driver/".concat(driverId, "/update"),
    method: "post",
    data: data
  });
}
function orderFood(driverId, listQuery) {
  return (0, _request.default)({
    url: "/driver/".concat(driverId, "/orderFood"),
    params: listQuery
  });
}
function orderDelivery(driverId, listQuery) {
  return (0, _request.default)({
    url: "/driver/".concat(driverId, "/orderDelivery"),
    params: listQuery
  });
}
function orderTransport(driverId, listQuery) {
  return (0, _request.default)({
    url: "/driver/".concat(driverId, "/orderTransport"),
    params: listQuery
  });
}