"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.map.js");
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("./mixins/resize"));
var _format = require("@/utils/format");
//
//
//
//
//
//
//

require("echarts/theme/macarons"); // echarts theme

var animationDuration = 6000;
var _default = {
  mixins: [_resize.default],
  props: {
    series: Array,
    attrGet: String,
    pointerName: String,
    dataChart: Array,
    format: {
      type: String,
      default: ""
    },
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "300px"
    },
    barColor: String
  },
  data: function data() {
    return {
      chart: null
    };
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      var self = this;
      this.chart = _echarts.default.init(this.$el, "macarons");
      this.chart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },

        grid: {
          top: 10,
          left: "2%",
          right: "2%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [{
          type: "category",
          data: this.dataChart.map(function (item) {
            return item.date;
          }),
          axisTick: {
            alignWithLabel: true
          }
        }],
        yAxis: [{
          type: "value",
          data: this.dataChart.map(function (item) {
            item.total;
          }),
          axisTick: {
            show: false
          }
        }],
        series: this.series
        // [
        //   {
        //     name: this.pointerName,
        //     type: "bar",
        //     stack: "vistors",
        //     barWidth: "60%",
        //     itemStyle: {
        //       color: this.barColor
        //     },
        //     data: this.dataChart.map(item => {
        //       return item[this.attrGet];
        //     }),
        //     animationDuration
        //   }
        // ]
      });
    }
  }
};
exports.default = _default;