"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DepositStore = _interopRequireDefault(require("./DepositStore.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    DepositStore: _DepositStore.default
  },
  data: function data() {
    return {};
  }
};
exports.default = _default;