"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PaymentStatus = exports.PaymentByTrans = exports.PaymentBy = exports.OrderDeliveryStatus = exports.OrderDeliveryDetailStatus = void 0;
var PaymentStatus = {
  PENDING: "Chờ thanh toán",
  COMPLETE: "Hoàn tất",
  FAIL: "Thất bại"
};
exports.PaymentStatus = PaymentStatus;
var PaymentByTrans = {
  SENDER: "Người gửi",
  RECEIVER: "Người nhận"
};
exports.PaymentByTrans = PaymentByTrans;
var PaymentBy;
exports.PaymentBy = PaymentBy;
(function (PaymentBy) {
  PaymentBy["Sender"] = "SENDER";
  PaymentBy["Receiver"] = "RECEIVER";
})(PaymentBy || (exports.PaymentBy = PaymentBy = {}));
var OrderDeliveryStatus;
exports.OrderDeliveryStatus = OrderDeliveryStatus;
(function (OrderDeliveryStatus) {
  OrderDeliveryStatus["PendingPayment"] = "PENDING_PAYMENT";
  OrderDeliveryStatus["FindDriver"] = "FIND_DRIVER";
  OrderDeliveryStatus["AcceptOrder"] = "ACCEPT_ORDER";
  OrderDeliveryStatus["Run"] = "RUN";
  OrderDeliveryStatus["Pay"] = "PAY";
  OrderDeliveryStatus["Complete"] = "COMPLETE";
  OrderDeliveryStatus["CustomerCancel"] = "CUSTOMER_CANCEL";
  OrderDeliveryStatus["DriverCancel"] = "DRIVER_CANCEL";
})(OrderDeliveryStatus || (exports.OrderDeliveryStatus = OrderDeliveryStatus = {}));
var OrderDeliveryDetailStatus;
exports.OrderDeliveryDetailStatus = OrderDeliveryDetailStatus;
(function (OrderDeliveryDetailStatus) {
  OrderDeliveryDetailStatus["Pending"] = "PENDING";
  OrderDeliveryDetailStatus["Complete"] = "COMPLETE";
})(OrderDeliveryDetailStatus || (exports.OrderDeliveryDetailStatus = OrderDeliveryDetailStatus = {}));